import moment from 'moment';

/**
 *
 * @param {string} stringifyAnswers
 * @description a stringify array of answers
 * @param {string} type
 * @description The question Type
 * @param {array} options
 * @description array of options for that particular question
 *
 */
export const makeAnswerText = (stringifyAnswers, type, options) => {
  const answersArray = JSON.parse(stringifyAnswers);
  const compiledAnswers = [];
  const typesWithIndex = ['multiple_choice', 'checkbox'];

  if (typesWithIndex.includes(type)) {
    answersArray.forEach(index => {
      const currentAnswer = options[index];
      compiledAnswers.push(currentAnswer?.text || currentAnswer?.value || '');
    });
  } else {
    compiledAnswers.push(answersArray[0]);
  }
  return compiledAnswers.join('\n');
};

const findPosition = (options, value) =>
  options.findIndex(op => op.text === value);

const mapAnswerSurveys = (answers, questionName, userSurveyId, options) => {
  const typesWithIndex = ['checkbox'];
  const answerFound = answers?.find(
    answ =>
      answ.question_name === questionName &&
      (userSurveyId === answ.care_team[0]?.user_survey_id ||
        userSurveyId === answ.patient[0]?.user_survey_id)
  );
  let answerFormat = null;

  if (answerFound) {
    const historyLenghAnswers = answerFound.care_team.length;
    const multipleAnswers = typesWithIndex.includes(answerFound.question_type);
    answerFormat = historyLenghAnswers
      ? answerFound.care_team[0].answer_text
      : null;

    if (answerFormat) {
      if (multipleAnswers) {
        answerFormat = answerFormat
          .split(',')
          .map(option => findPosition(options, option.trim()));
      } else if (answerFound.question_type === 'multiple_choice') {
        answerFormat = findPosition(options, answerFormat);
      }
    } else {
      answerFormat = answerFound.patient[0].answer_text;
      if (multipleAnswers && !Array.isArray(answerFormat)) {
        answerFormat = answerFormat
          .split(',')
          .map(option => findPosition(options, option.trim()));
      } else if (answerFound.question_type === 'multiple_choice') {
        answerFormat = findPosition(options, answerFormat);
      }
    }
  }

  return answerFormat;
};

export const parseSurveyQuestions = (survey, answers) =>
  survey.questions.map(question => {
    const { text: summary, name: date, type, options } = question;

    let answer = mapAnswerSurveys(
      answers,
      date,
      survey.user_survey_id,
      options
    );

    return {
      date,
      summary,
      extraText: { compiledAnswers: answer, type, options },
    };
  });

export const previewQuestions = (surveys, answers) => {
  const questions = [];
  const mostRecent = [...surveys].reverse();
  mostRecent.forEach(survey => {
    if (questions.length === 3) {
      return;
    }
    const dateOnMiliSeconds = survey.time_completed * 1000;
    const date = moment(dateOnMiliSeconds).fromNow();
    survey.questions.forEach(question => {
      const {
        answer_selected: stringifyAnswers,
        text: summary,
        name,
        type,
        options,
      } = question;

      if (!stringifyAnswers || questions.length === 3) return;

      const answer = mapAnswerSurveys(
        answers,
        name,
        survey.user_survey_id,
        options
      );
      const arrAnswer = Array.isArray(answer) ? answer : [answer];
      const extraText = makeAnswerText(
        JSON.stringify(arrAnswer),
        type,
        options
      );

      questions.push({ date, summary, extraText });
    });
  });
  return questions;
};

export const filterSurveys = (surveys, filters) => {
  const filteredSurveys = surveys
    .slice()
    .filter(
      survey =>
        !filters.headers.length || filters.headers.includes(survey.intro_header)
    )
    .filter(
      survey => !filters.types.length || filters.types.includes(survey.type)
    )
    .filter(
      survey =>
        !filters.id || parseInt(filters.id) === parseInt(survey.survey_id)
    )
    .filter(
      survey =>
        !filters.status?.length || filters.status?.includes(survey.status)
    )
    .sort((a, b) => {
      if (filters.orderBy === 'priority') {
        return b.priority - a.priority;
      }

      return 0;
    });

  if (filters.orderBy === 'newest') {
    return filteredSurveys.reverse();
  }

  return filteredSurveys;
};

export const removeDuplicateOptions = options =>
  options.reduce(
    (a, b) => (a.find(option => option.value === b.value) ? a : [...a, b]),
    []
  );

export const createOptionsByField = (surveys, field) =>
  surveys.map((survey, key) => ({
    key,
    text: survey[field],
    value: survey[field],
  }));
